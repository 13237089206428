<template>
  <div>
    <!-- 中文 -->
    <div v-if="$store.state.show">
      <h2>贴纸Tools相关</h2>
      <p>
        如在自己设计贴纸并使用贴纸配置工具生成配置文件实测时出现问题，可排查以下注意点：
      </p>
      <ul>
        <li>1.确定贴纸文件为png格式；</li>
        <li>
          2.确定生成的贴纸配置文件config.json均为正确无误的，查看是否有null值，空值，非法值等；
        </li>
        <li>
          3.建议以资源文件本地化的方式测试结果（确保路径，文件结构，必要文件名称正确等）；
        </li>
        <li>4.如需Tools相关帮助，请在技术支持群内提问。</li>
      </ul>
    </div>
    <!-- 英文 -->
    <div v-else>
      <h2>Sticker Tools Related</h2>
      <p>
        If you have problems designing your own stickers and using the Sticker
        Configuration Tool to generate the actual tests of the configured files,
        you can check the following points of attention:
      </p>
      <ul>
        <li>1.Make sure the sticker file is in PNG format;</li>
        <li>
          2.Determine that the resulting sticker profiles(config.json) are
          correct and check for ‘null’, null, illegal values, etc.
        </li>
        <li>
          3.It is recommended that the results be tested in a localized way for
          resource files (ensure that the path, file structure, necessary file
          name, and so on are correct);
        </li>
        <li>
          4.If you need help with Tools, ask questions within the Technical
          Support group.
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #3c4858;
}
p,
li {
  width: 763px;
  margin: 20px 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
</style>